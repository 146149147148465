import React from 'react'
const styling =  {
  border: 0,
  /*'box-shadow': '5px 5px 56px 0px rgba(0,0,0,0.25)'*/
};

const Iframe = () => {
    return <div className="cisco-partner-form">
        <div className="emdeb-responsive">
        <iframe 
          title='mondayForm' 
          src="https://forms.monday.com/forms/embed/c19f156061cab05af193c993e5f0c2c7?r=use1" 
          width="100%"
          height="1200" 
          style= {styling} >
        </iframe> 
        </div>
    </div>
};

export default Iframe;